import Constants from '../../../utils/Constants';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import separator from '../../../assets/imgs/separator.svg';
import { CapitalizeFirstLeter } from '../../../utils/CapitalizeFirstLetter';
import { useEffect } from 'react';
import Slideshow from '../../../components/02_slideshow/Slideshow';
import {
  ADDITIONAL_SERVICES,
  BATHROOM,
  BED_FEATURES,
  DINING,
  DISABILITY_FEATURES,
  ENTERTAINMENT,
  OFFICE_TELECOM,
  ROOM_FEATURES,
} from '../amenities';

const images = require.context(
  '../../../assets/imgs/09_accommodations/comfortRooms',
  true
);
const imagesList = images.keys().map((image: any) => images(image));

const ComfortRoom = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.COMFORT_ROOM)}`;
  }, []);

  return (
    <div id={Constants.COMFORT_ROOM} className='accommodations_wrapper'>
      <div className='accommodations_hero'>
        <img src={imagesList[0]} alt={`${Constants.COMFORT_ROOM} hero`} />
        <div className='accommodations_hero_title'>
          <h1>COMFORT</h1>
        </div>
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <div className='accommodations_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <div className='accommodations_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp' duration={500}>
                <h2 className='title_underline_complementary'>
                  {CapitalizeFirstLeter(Constants.COMFORT_ROOM)}S (SGL USE,
                  DBL/TWIN)
                </h2>
                <p>
                  Comfort single, double or twin rooms are elegantly appointed
                  and thoughtfully equipped with everything you need for a cozy
                  stay, featuring comfortable bed (180 x 200 cm) with fine
                  bedding, a writing desk, Smart TV and modernly decorated
                  bathroom.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
                <div className='accommodations_container_info_text_button'>
                  <a
                    href={Constants.bookNowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>RESERVE NOW</div>
                  </a>
                </div>
              </ScrollAnimation>
            </div>
            <div className='accommodations_container_info_details'>
              <div>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ENTERTAINMENT.title}</p>
                  <ul>
                    {ENTERTAINMENT.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{BED_FEATURES.title}</p>
                  <ul>
                    {BED_FEATURES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{BATHROOM.title}</p>
                  <ul>
                    {BATHROOM.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{OFFICE_TELECOM.title}</p>
                  <ul>
                    {OFFICE_TELECOM.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{DINING.title}</p>
                  <ul>
                    {DINING.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{DISABILITY_FEATURES.title}</p>
                  <ul>
                    {DISABILITY_FEATURES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
              </div>
              <div>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ROOM_FEATURES.title}</p>
                  <ul>
                    <li>
                      Size of Single rooms: 17-19 m<sup>2</sup>
                    </li>
                    <li>
                      Size of Twin or Double rooms: 22-29 m<sup>2</sup>
                    </li>
                    <li>Slippers</li>
                    {ROOM_FEATURES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ADDITIONAL_SERVICES.title}</p>
                  <ul>
                    {ADDITIONAL_SERVICES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                    <ul>
                      <li>{ADDITIONAL_SERVICES.gifts}</li>
                    </ul>
                    <li>{ADDITIONAL_SERVICES.li_2}</li>
                    <ul>
                      {ADDITIONAL_SERVICES.pets.map((li) => (
                        <li>{li}</li>
                      ))}
                    </ul>
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>OTHER</p>
                  <ul>
                    <li>Courtyard view</li>
                    <li>Cornaro Stay Safe standard</li>
                    <li>Hotel laundry - available as per request, as per price list and safety standards</li>
                    <li>Free Gym use</li>
                    <li>Free use of Business centre - as per reservation</li>
                  </ul>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className='gallery'>
        <div className='gallery_container container'>
          <h2>GALLERY</h2>
          <div className='gallery_container_imgs'>
            <Slideshow slideshowImages={imagesList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComfortRoom;
