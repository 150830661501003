import Constants from '../../../../utils/Constants';
import atGlance from '../../../../assets/imgs/home/08_glance/at_glance.png';
import mapAndDirectionsPdf from '../../../../assets/documents/home/footer/Conraro_arrival.pdf';

const Glance = () => {
  return (
    <div id={Constants.AUTHENTICITY} className='glance'>
      <div className='container'>
        <h2 className='text_center'>AT GLANCE</h2>

        <div className='glance_container'>
          <div className='glance_container_text'>
            <div>
              <p>
                <b>Property:</b> Cornaro Hotel*****
              </p>
              <p>
                <b>Opening year:</b> 2014.
              </p>
              <p>
                <b>Reconstruction year:</b> 2024.
              </p>
              <p>
                <b>Open:</b> 24 / 7 / 365
              </p>
              <p>
                <b>Parking:</b> on-site parking / garage parking
              </p>
              <p>
                <b>City:</b> Split, Croatia
              </p>
              <p>
                <b>151</b> rooms, <b>2</b> junior suites, <b>2</b> superior,
                <b> 2</b> deluxe and <b>1</b> premium suite
              </p>
              <p>
                <b>Services:</b> breakfast, A la carte restaurant, Split Rooftop
                Bar, wellness, conference services, transfers
              </p>
            </div>
          </div>
          <div className='glance_container_img'>
            <img
              className='glance_container_img_logo'
              src={atGlance}
              alt='At glance'
            />
          </div>
        </div>

        <a
          href={mapAndDirectionsPdf}
          className='opacity_transition_low glance_button button'
          target='_blank'
          rel='noreferrer'
        >
          <div>MAP & DIRECTIONS</div>
        </a>
      </div>
    </div>
  );
};

export default Glance;
