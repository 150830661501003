export const ENTERTAINMENT = {
  title: 'ENTERTAINMENT',
  li: [
    'Smart TV with Cable/Satellite channels',
    'Tablet with Cornaro Hotel Concierge app (per request)',
  ],
};

export const BED_FEATURES = {
  title: 'BED FEATURES',
  li: ['High-class collection bed sheets', 'Hypoallergenic pillow'],
  li_2: ['Pillow menu', 'Turndown service'],
};

export const BATHROOM = {
  title: 'BATHROOM',
  li: [
    'Bathroom with bathtub or walk-in shower',
    'Hairdryer',
    'Tea Matcha cosmetic assortment',
    'Weight scale',
    'Long-stay amenities (per request)',
  ],
};

export const OFFICE_TELECOM = {
  title: 'OFFICE/TELECOM',
  li: [
    'Telephone',
    'Complimentary wireless high-speed internet access',
    'Wake-up service (per request)',
    'Universal electric plug (per request)',
  ],
};

export const DINING = {
  title: 'DINING',
  li: [
    'Minibar',
    'Kettle (tea, coffee)',
    'Room service (per request, charged according to the applicable hotel rates)',
  ],
};

export const ROOM_FEATURES = {
  title: 'ROOM FEATURES',

  li: [
    'Electronic door lock',
    'Soundproofed',
    'Non-smoking',
    'Air-conditioning',
    'Individual temperature control',
    'Writing desk',
    'Reading light',
    'In-room electronic safe (accommodates laptop)',
    'Laundry bag',
    'Bathroom floor heating',
  ],
};

export const ADDITIONAL_SERVICES = {
  title: 'ADDITIONAL SERVICES',
  li: [
    'City map',
    'Beach accessories (per request, info & price list available at Front Desk)',
    'Crib (per request, info & price list available at Front Desk)',
    'Welcome gifts for children (info available at Front Desk)',
  ],
  gifts: 'Coloring book & crayons or puzzle & crayons',
  li_2: 'Services for pets (per request, info & price list available at Front Desk)',
  pets: [
    'Pet bed placed in the room for use throughout the stay',
    'Food/water bowls placed in the room for use throughout the stay',
    'Fetching toy (gift)',
  ],
};

export const DISABILITY_FEATURES = {
  title: 'DISABILITY FEATURES',
  li: [
    'Disability accessible sgl rooms available',
    'Shower chair for persons with disabilities',
  ],
};

export const WELLNESS = {
  title: 'WELLNESS',
  li: [
    'Free use of the wellness jacuzzi (prior reservation required)',
    'Free use of the rooftop jacuzzi (prior reservation required)',
  ],
};
