import { useEffect } from 'react';
import Slideshow from '../../components/02_slideshow/Slideshow';
import Constants from '../../utils/Constants';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';

const gallery = require.context('../../assets/imgs/home/07_gallery', true);
const galleryList = gallery.keys().map((image: any) => gallery(image));

const Gallery = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.GALLERY)}`;
  }, []);

  return (
    <div id={Constants.GALLERY} className='gallery'>
      <div className='gallery_container container'>
        <h2>GALLERY</h2>
        <div className='gallery_container_imgs'>
          <Slideshow slideshowImages={galleryList} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
