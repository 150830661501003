import Constants from '../../../utils/Constants';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import separator from '../../../assets/imgs/separator.svg';
import { CapitalizeFirstLeter } from '../../../utils/CapitalizeFirstLetter';
import { useEffect } from 'react';
import Slideshow from '../../../components/02_slideshow/Slideshow';
import {
  ADDITIONAL_SERVICES,
  BATHROOM,
  BED_FEATURES,
  DINING,
  ENTERTAINMENT,
  OFFICE_TELECOM,
  ROOM_FEATURES,
  WELLNESS,
} from '../amenities';

const images = require.context(
  '../../../assets/imgs/09_accommodations/deluxCityViewRooms',
  true
);
const imagesList = images.keys().map((image: any) => images(image));

const DeluxeCityView = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.DELUXE_CITY_VIEW_ROOM)}`;
  }, []);

  return (
    <div
      id={Constants.DELUXE_CITY_VIEW_ROOM}
      className='accommodations_wrapper'
    >
      <div className='accommodations_hero'>
        <img
          src={imagesList[0]}
          alt={`${Constants.DELUXE_CITY_VIEW_ROOM} hero`}
        />
        <div className='accommodations_hero_title'>
          <h1>DELUXE</h1>
        </div>
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <div className='accommodations_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <div className='accommodations_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp' duration={500}>
                <h2 className='title_underline_complementary'>
                  {CapitalizeFirstLeter(Constants.DELUXE_CITY_VIEW_ROOM)}S (SGL,
                  SGL USE, DBL/TWIN)
                </h2>
                <p>
                  Rich fabrics and furnishings give a luxurious feel to these
                  Deluxe rooms which provide you with comfort, space, modern-day
                  luxuries and a spectacular view overlooking the city center.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
                <div className='accommodations_container_info_text_button'>
                  <a
                    href={Constants.bookNowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>RESERVE NOW</div>
                  </a>
                </div>
              </ScrollAnimation>
            </div>
            <div className='accommodations_container_info_details'>
              <div>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ENTERTAINMENT.title}</p>
                  <ul>
                    {ENTERTAINMENT.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{BED_FEATURES.title}</p>
                  <ul>
                    {BED_FEATURES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                    {BED_FEATURES.li_2.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{BATHROOM.title}</p>
                  <ul>
                    {BATHROOM.li.map((li) => (
                      <li>{li}</li>
                    ))}
                    <li>Chopard cosmetic set on a stone stand</li>
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{OFFICE_TELECOM.title}</p>
                  <ul>
                    {OFFICE_TELECOM.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{DINING.title}</p>
                  <ul>
                    <li>Welcome bottle of wine</li>
                    {DINING.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{WELLNESS.title}</p>
                  <ul>
                    {WELLNESS.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
              </div>
              <div>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ROOM_FEATURES.title}</p>
                  <ul>
                    <li>
                      Size of Twin or Double rooms: 25,5 - 36,9 m<sup>2</sup>
                    </li>
                    <li>Bathrobes & slippers</li>
                    {ROOM_FEATURES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>{ADDITIONAL_SERVICES.title}</p>
                  <ul>
                    {ADDITIONAL_SERVICES.li.map((li) => (
                      <li>{li}</li>
                    ))}
                    <ul>
                      <li>{ADDITIONAL_SERVICES.gifts}</li>
                    </ul>
                    <li>{ADDITIONAL_SERVICES.li_2}</li>
                    <ul>
                      {ADDITIONAL_SERVICES.pets.map((li) => (
                        <li>{li}</li>
                      ))}
                    </ul>
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' duration={500}>
                  <p>OTHER</p>
                  <ul>
                    <li>Old city walls</li>
                    <li>Cornaro Stay Safe standard</li>
                    <li>
                      Hotel laundry - available as per request, as per price
                      list and safety standards
                    </li>
                    <li>Welcome gift in the room</li>
                    <li>Free use of Rooftop Jacuzzi - as per reservation</li>
                    <li>Free Gym use</li>
                    <li>Free use of Business centre - as per reservation</li>
                    <li>Tablet with Cornaro Hotel Concierge app (per request)</li>
                  </ul>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className='gallery'>
        <div className='gallery_container container'>
          <h2>GALLERY</h2>
          <div className='gallery_container_imgs'>
            <Slideshow slideshowImages={imagesList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeluxeCityView;
